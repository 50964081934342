import React from "react"

import { SEO } from "../components"
import { ComplaintHandling } from "../sections/complaints"

const InsuranceBasics: React.FC = () => (
  <>
    <SEO
      title="Complaints"
      description="Lodge complaints with care at Workerslife. Escalate to Compliance, and if needed, seek external resolution through FAIS Ombudsman."
    />
    <ComplaintHandling />
  </>
)
export default InsuranceBasics
