import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import { Article } from "../../components"

const PATH = "../../assets/images/complaints/"

const InsuranceExplained = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  const ArticleProperties = {
    className: "article",
  }

  return (
    <Container>
      <Article
        title="Committed to handling your complaint..."
        image={
          <StaticImage src={`${PATH}complaints-1.png`} {...ImageProperties} />
        }
        isSubComponent={false}
        {...ArticleProperties}
      >
        <p>
          As a Workerslife customer you can expect to be treated with care and
          respect. Whilst we certainly can’t claim to always get things right,
          we try our absolute best to deliver on your expectations.
        </p>
        <p>
          If you are unhappy with your experience, it is important for you to
          let us know.
        </p>
      </Article>
      <Article
        title={`Step One\nHow to complain...`}
        image={
          <StaticImage src={`${PATH}complaints-2.png`} {...ImageProperties} />
        }
        imageLeft
        {...ArticleProperties}
      >
        <p>
          To let us know about your complaint you may use any one of the
          following channels below.
        </p>
        <p>
          Click on the link below and complete an online form via the
          Workerslife website <a>www.workerslife.co.za</a>
        </p>
        <p>
          Refer to your policy wording as well as schedule of insurance, to
          obtain the relevant contact details and complaints procedure.
        </p>
        <p className="grid col">
          <span>Send an email to</span>
          <span>
            <a href="mailto:complaints@workerslife.co.za?subject=Policy%20Complaint">
              complaints@workerslife.co.za
            </a>
          </span>
        </p>
        <p>
          Contact the Workerslife switchboard on{" "}
          <a href="tel:012 305 7400">{"012 305 7400"}</a>, where one of our
          agents will direct your complaint to the responsible area for
          resolution.
        </p>
        <p className="grid col">
          <span>You may write to us at:</span>
          <span>Workerslife Complaints Manager</span>
          <span>
            <b>P.O. Box 11497, Hatfield, 0028.</b>
          </span>
        </p>
      </Article>
      <Article
        title={`Step Two\nHow to escalate your complaint if you are not satisfied with our decision...`}
        image={
          <StaticImage src={`${PATH}complaints-3.png`} {...ImageProperties} />
        }
        {...ArticleProperties}
      >
        <p>
          If you are still unhappy after you have asked us to review our
          decision, you may request for your complaint to be referred to the
          Compliance Department which will investigate your complaint
          objectively and independently.
        </p>
        <p>
          <a href="mailto:compliance@workerslife.co.za?subject=Request%20for%20complaint%20referral%20to%20Compliance%20Department">
            compliance@workerslife.co.za
          </a>
        </p>
        <p className="grid">
          <span>Contact Number</span>
          <span>
            <a href="tel:0861 520 520">{"0861 520 520"}</a>
          </span>
        </p>
        <p className="grid">
          <span>Fax Number</span>
          <span>
            <a href="tel:0861 530 530">{"0861 530 530"}</a>
          </span>
        </p>
      </Article>
      <Article
        title={`Step Three\nYour option to refer your complaint for external dispute resolution...`}
        image={
          <StaticImage src={`${PATH}complaints-4.png`} {...ImageProperties} />
        }
        imageLeft
        {...ArticleProperties}
      >
        <p>
          If the complaint is still not resolved to your satisfaction by
          Workerslife and if you are still unhappy after following Step 1 or
          Step 2, you may submit your complaint to the relevant Ombudsman,
          subject to their area of jurisdiction.
        </p>
        <p>
          <b>FAIS (FINANCIAL ADVISORY AND INTERMEDIARY SERVICES) OMBUDSMAN</b>
        </p>
        <p>
          For complaints relating to the sale of your policy, or the
          intermediary services provided by the Financial Advisor or Broker who
          assisted you.
        </p>
        <p className="grid">
          <span>Tel No</span>
          <span>
            <a href="tel:012 762 5000">{"012 762 5000"}</a>
            {" / "}
            <a href="tel:086 066 3274">{"086 066 3274"}</a>
          </span>
        </p>
        <p>
          <a href="mailto:info@faisombud.co.za?subject=Request%20for%20external%20dispute%20resolution%20of%20complaint%20by%20Ombudsman">
            info@faisombud.co.za
          </a>
        </p>
        <p className="grid">
          <span>Fax No</span>
          <span>
            <b>012 348 3447</b>
            {" / "}
            <b>012 470 9097</b>
          </span>
        </p>
        <p className="grid">
          <span>Address</span>
          <span>
            <b>The FAIS Ombud P.O. Box 41 Menlyn Park 0063</b>
          </span>
        </p>
      </Article>
    </Container>
  )
}

export default InsuranceExplained

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 58px;

  .article {
    a {
      cursor: pointer;
    }

    a,
    b {
      font-weight: 500;
    }

    p.grid {
      column-gap: 20px;
      display: grid;

      &:not(.col) {
        grid-template-columns: max-content 1fr;
      }

      b,
      span {
        display: inline-block;
      }
    }

    h1 {
      white-space: pre-wrap;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      &:nth-child(n + 2) {
        h1 {
          font-size: 52px;
          line-height: 69px;
        }
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
      &:nth-child(n + 2) {
        .textContainer {
          margin: 20px 0;
        }
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
      &:nth-child(n + 2) {
        .textContainer {
          margin: 45px 0 70px 0;
        }
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    gap: 140px;
  }
`
